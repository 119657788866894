import useSubscription from '@hooks/useSubscription';
import { SiteMeta } from '@src/types/SiteMeta';
import storeConnector from '@store/storeConnector';
import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CommandsCategory } from '@src/types/Commands';
import useDataPointsSearch from '@src/hooks/api/queries/useDataPointsSearch';
import { DataPointWithValue } from '@src/types/DataPoint';
import PowerSection from './PowerSection';
import { CommandsModalContextProvider } from '../CommandsModalContext';

const StyledDialogTitle = styled(DialogTitle)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

interface Props {
  deviceName: string;
  open: boolean;
  onClose: () => void;
  siteMeta: SiteMeta;
}

function PowinControllerCommandsModal({
  deviceName,
  open,
  onClose,
  siteMeta,
}: Props) {
  const { data: dataPoints } = useDataPointsSearch('cmd-modal-power', [
    { category: 'site', pointName: 'Pset' },
    { category: 'site', pointName: 'Qset' },
  ]);
  const dataPointsWithValue: DataPointWithValue[] = useSubscription(
    dataPoints || [],
  );

  return (
    <CommandsModalContextProvider
      category={CommandsCategory.POWINEMS}
      deviceId={siteMeta.siteId}
    >
      <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
        <StyledDialogTitle>
          {deviceName} Commands
          <IconButton onClick={onClose} sx={{ mr: -2, mt: -1 }}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>
        <DialogContent dividers>
          <PowerSection
            currentPset={String(
              dataPointsWithValue.find((o) => o.pointName === 'Pset')?.value,
            )}
            currentQset={String(
              dataPointsWithValue.find((o) => o.pointName === 'Qset')?.value,
            )}
          />
        </DialogContent>
      </Dialog>
    </CommandsModalContextProvider>
  );
}

export default storeConnector(PowinControllerCommandsModal, {
  config: ['siteMeta'],
  user: ['role'],
});
