import { Button, Grid2 } from '@mui/material';
import React from 'react';
import { POWIN_COMMANDS } from '@src/types/Commands';
import { useCommandsModalContext } from '../CommandsModalContext';

function DCContactor() {
  const { sendCommand } = useCommandsModalContext();

  const handleClickOpen = (): void => {
    sendCommand(POWIN_COMMANDS.OPEN_DC_CONTACTORS);
  };

  const handleClickClose = (): void => {
    sendCommand(POWIN_COMMANDS.CLOSE_DC_CONTACTORS);
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2
        size={{ xs: 4 }}
        sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
      >
        DC Contactors:
      </Grid2>
      <Grid2 size={{ xs: 8 }}>
        <Button variant='contained' onClick={handleClickOpen}>
          Open&nbsp;
        </Button>
        <Button variant='contained' sx={{ ml: 10 }} onClick={handleClickClose}>
          Close
        </Button>
      </Grid2>
    </Grid2>
  );
}

export default DCContactor;
