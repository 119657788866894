import { Button, Grid2 } from '@mui/material';
import React from 'react';
import { POWIN_COMMANDS } from '@src/types/Commands';
import { useCommandsModalContext } from '../CommandsModalContext';

function PCS() {
  const { sendCommand } = useCommandsModalContext();

  const handleClickStartPCS = (): void => {
    sendCommand(POWIN_COMMANDS.PCS_START);
  };

  const handleClickStopPCS = (): void => {
    sendCommand(POWIN_COMMANDS.PCS_STOP);
  };

  return (
    <Grid2 container spacing={2}>
      <Grid2
        size={{ xs: 4 }}
        sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}
      >
        PCS:
      </Grid2>
      <Grid2 size={{ xs: 8 }}>
        <Button variant='contained' onClick={handleClickStartPCS}>
          Start
        </Button>
        <Button
          variant='contained'
          sx={{ ml: 10 }}
          onClick={handleClickStopPCS}
        >
          Stop
        </Button>
      </Grid2>
    </Grid2>
  );
}

export default PCS;
