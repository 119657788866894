import TextLoader from '@components/_elements/TextLoader';
import getTemperatureString from '@src/utils/getTemperatureString';
import storeConnector from '@store/storeConnector';
import { useWsSubscribe } from '@store/actionCreators/mqtt.js';
import { useInterval } from '@utils';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { COMMON_BATTERY_FIELDS, getRackFields } from './constants.js';
import getAverageValue from './getAverageValue';
import Popup from '@src/components/_elements/Popup/Popup.jsx';
import { RackContainerPopup } from './RackContainerPopup';
import { getTopicForTooltip } from '@utils/index_ts';

const RackContainer_default = ({
  siteMeta,
  currentUnitDeviceId,
  battType,
  fromPV,
  bmsId,
  bmsIndex,
  topicDict,
}) => {
  const [currHBInterval, setCurrHBInterval] = useState(0);
  const [prevHB, setPrevHB] = useState(0);
  const [currCounterInterval, setCurrCounterInterval] = useState(0);
  const [prevCounter, setPrevCounter] = useState(0);
  const [rackRt, setRackRt] = useState({});
  const [batteryRt, setBatteryRt] = useState({});
  const [isRackPopupOpen, setIsRackPopupOpen] = useState(false);
  const [selectedRack, setSelectedRack] = useState(null);
  const currentUnitSNRef = useRef();
  currentUnitSNRef.current = currentUnitDeviceId;

  const { NumRacks, Batt_perUnit_BMS_count: numBms, Units } = siteMeta;
  const { Battery_BATT_RackTable_Enable } = siteMeta.ui;

  const rackCountOverride = siteMeta.Topology_Override?.bmsRack?.find(
    (o) => o.unitSN === currentUnitDeviceId && o.bmsId === bmsId,
  );
  const racksPerBMS = rackCountOverride
    ? rackCountOverride.rackCount
    : Math.floor(Number(NumRacks) / Number(numBms) / Units.length);

  const isRackPointsDrivenBySiteMeta = Array.isArray(
    siteMeta.ui.rackPointNames,
  );

  const getKey = (key, prefix) =>
    siteMeta.ui.Use_New_Topic_Structure ? key : `${prefix}${key}`;

  useEffect(() => {
    setBatteryRt({});
    setRackRt({});
  }, [currentUnitDeviceId]);

  const batteryFields = siteMeta.ui.Use_New_Topic_Structure
    ? COMMON_BATTERY_FIELDS
    : COMMON_BATTERY_FIELDS.map((f) => `${bmsId}` + f);

  const unitNameIdx = siteMeta.Units.indexOf(currentUnitDeviceId);
  const unitName = unitNameIdx !== -1 ? siteMeta.UnitNames[unitNameIdx] : '';
  const rackPointNames = siteMeta.ui.rackPointNames || getRackFields(battType);

  const rackIds = Array.from(Array(racksPerBMS)).map(
    (_o, i) => `${bmsId}_rack_${i + 1}`,
  );

  useWsSubscribe(
    {
      bms: {
        sns: [currentUnitDeviceId],
        fields: batteryFields,
        sourceDeviceId: `bms_${bmsIndex + 1}`,
        cb: (data) => {
          setBatteryRt((prev) => ({ ...prev, ...data }));
        },
      },
      rack: {
        sns: [currentUnitDeviceId],
        fields: rackPointNames,
        sourceDeviceIds: rackIds,
        cb: (data, _sn, _ts, extraVars) => {
          setRackRt((prev) => {
            return {
              ...prev,
              [extraVars.sourceDeviceId]: {
                ...prev[extraVars.sourceDeviceId],
                ...data,
              },
            };
          });
        },
      },
    },
    [currentUnitDeviceId],
  );

  useInterval(() => {
    if (batteryRt?.[getKey('SysHB', bmsId)]) {
      if (batteryRt[getKey('SysHB', bmsId)] !== prevHB) {
        setCurrHBInterval(0);
        setPrevHB(batteryRt[getKey('SysHB', bmsId)]);
        setCurrCounterInterval(0);
      } else {
        setCurrHBInterval((currHBInterval) => currHBInterval + 1);
        setCurrCounterInterval(0);
      }
    } else {
      setCurrHBInterval(0);
      setPrevHB(0);
      if (batteryRt?.Counter && batteryRt.Counter !== prevCounter) {
        setCurrCounterInterval(0);
        setPrevCounter(batteryRt.Counter);
      } else {
        setCurrCounterInterval(
          (currCounterInterval) => currCounterInterval + 1,
        );
      }
    }
  }, 1000);

  const formatValue = (val, toFixed) => {
    if ([undefined, null, NaN].includes(val) || (!+val && +val !== 0)) {
      return '-';
    }
    return toFixed ? (+val).toFixed(toFixed) : +val;
  };

  const getRackName = (index) => {
    const { RackNames } = siteMeta;
    if (RackNames) {
      return RackNames[index];
    } else {
      return (index < 9 ? '0' : '') + (index + 1);
    }
  };

  const rackObjects = useMemo(
    () =>
      rackIds.map((rackId, i) => ({
        name: getRackName(i),
        id: rackId,
      })),
    [rackIds],
  );

  const getRackArr = (rackRtObj) => {
    const rackKeys = rackIds;
    return rackKeys.map((rackId) => {
      const rackDataObj = rackRtObj[rackId] || {};
      if (isRackPointsDrivenBySiteMeta) {
        return rackDataObj;
      }
      const alarms = rackDataObj.Alarms;
      let minCellV = rackDataObj.MinCellV;
      let maxCellV = rackDataObj.MaxCellV;
      let minCellT = rackDataObj.MinCellT;
      let maxCellT = rackDataObj.MaxCellT;
      return {
        mode: rackDataObj.RackMode,
        minCellV: formatValue(minCellV, 3),
        maxCellV: formatValue(maxCellV, 3),
        deltaV: formatValue(maxCellV - minCellV, 3),
        minCellT: formatValue(minCellT),
        maxCellT: formatValue(maxCellT),
        deltaT: formatValue(maxCellT - minCellT),
        rackVoltage: formatValue(rackDataObj.RackVoltage, 1),
        rackCurrent: formatValue(rackDataObj.RackCurrent, 1),
        SOC: formatValue(rackDataObj.RackSOC, 1),
        SOH: formatValue(rackDataObj.RackSOH, 1),
        alarms: Number.isInteger(alarms) ? alarms : '-',
      };
    });
  };

  const racksArr = useMemo(() => {
    return getRackArr(rackRt);
  }, [rackRt]);

  const getInfoDict = () => {
    const {
      Thresh_CellTMax,
      Thresh_CellTMin,
      Thresh_CellVHigh,
      Thresh_CellVLow,
      Thresh_DeltaCellT,
      Thresh_HB_BMS_TimeOut,
      Thresh_LowerSOC,
      Thresh_UpperSOC,
      Thresh_LowerSOH,
    } = siteMeta ? siteMeta : {};

    return [
      {
        field: 'Power (kW)',
        value: formatValue(
          (batteryRt[getKey('SysVoltage', bmsId)] *
            batteryRt[getKey('SysCurrent', bmsId)]) /
            1000,
          1,
        ),
        loading:
          isBatteryLoading('SysVoltage') || isBatteryLoading('SysCurrent'),
        topicTooltip: getTopicForTooltip(
          'SysVoltage * SysCurrent / 1000',
          topicDict,
        ),
      },
      {
        field: 'System Voltage (V)',
        value: batteryRt[getKey('SysVoltage', bmsId)],
        loading: isBatteryLoading('SysVoltage'),
        expectedSize: 5,
        topicTooltip: getTopicForTooltip('SysVoltage', topicDict),
      },
      {
        field: 'Average Cell V',
        value:
          batteryRt[getKey('AvgCellV', bmsId)] ??
          getAverageValue(rackRt.AvgCellVoltageValue)?.toFixed(3),
        loading:
          isBatteryLoading('AvgCellV') && isRackLoading('AvgCellVoltageValue'),
        expectedSize: 4,
        topicTooltip: getTopicForTooltip('AvgCellV', topicDict),
      },
      {
        field: 'Average Cell T',
        value: getTemperatureString(
          batteryRt[getKey('AvgCellT', bmsId)] ??
            getAverageValue(rackRt.AvgCellTemp),
        ),
        loading: isBatteryLoading('AvgCellT') && isRackLoading('AvgCellTemp'),
        topicTooltip: getTopicForTooltip('AvgCellT', topicDict),
      },
      {
        field: 'System SOC',
        value: batteryRt[getKey('SysSOC', bmsId)],
        class:
          (+batteryRt.SysSOC > +Thresh_UpperSOC ? 'red-txt' : '') +
          (+batteryRt.SysSOC < +Thresh_LowerSOC ? 'blue-text' : ''),
        loading: isBatteryLoading('SysSOC'),
        topicTooltip: getTopicForTooltip('SysSOC', topicDict),
      },
      {
        field: 'System Current (A)',
        value: batteryRt[getKey('SysCurrent', bmsId)],
        loading: isBatteryLoading('SysCurrent'),
        topicTooltip: getTopicForTooltip('SysCurrent', topicDict),
      },
      {
        field: 'Max Cell V',
        value: batteryRt[getKey('MaxCellV', bmsId)],
        class: +batteryRt.MaxCellV > +Thresh_CellVHigh ? 'red-txt' : '',
        loading: isBatteryLoading('MaxCellV'),
        expectedSize: 4,
        topicTooltip: getTopicForTooltip('MaxCellV', topicDict),
      },
      {
        field: 'Max Cell T',
        value: getTemperatureString(batteryRt[getKey('MaxCellT', bmsId)]),
        class: +batteryRt.MaxCellT > +Thresh_CellTMax ? 'red-txt' : '',
        loading: isBatteryLoading('MaxCellT'),
        topicTooltip: getTopicForTooltip('MaxCellT', topicDict),
      },
      {
        field: 'System SOH',
        value: batteryRt[getKey('SysSOH', bmsId)],
        class: +batteryRt.SysSOH < +Thresh_LowerSOH ? 'red-txt' : '',
        loading: isBatteryLoading('SysSOH'),
        topicTooltip: getTopicForTooltip('SysSOH', topicDict),
      },
      {
        field: 'Chrg Power Lim',
        value: batteryRt[getKey('ChaCurrLimit', bmsId)],
        loading: isBatteryLoading('ChaCurrLimit'),
        expectedSize: 5,
        topicTooltip: getTopicForTooltip('ChaCurrLimit', topicDict),
      },
      {
        field: 'Min Cell V',
        value: batteryRt[getKey('MinCellV', bmsId)],
        class: +batteryRt.MinCellV < +Thresh_CellVLow ? 'blue-text' : '',
        loading: isBatteryLoading('MinCellV'),
        expectedSize: 3,
        topicTooltip: getTopicForTooltip('MinCellV', topicDict),
      },
      {
        field: 'Min Cell T',
        value: getTemperatureString(batteryRt[getKey('MinCellT', bmsId)]),
        class: +batteryRt.MinCellT < +Thresh_CellTMin ? 'blue-text' : '',
        loading: isBatteryLoading('MinCellT'),
        topicTooltip: getTopicForTooltip('MinCellT', topicDict),
      },
      {
        field: 'BMS Heartbeat',
        value: batteryRt[getKey('SysHB', bmsId)],
        class: currHBInterval > +Thresh_HB_BMS_TimeOut ? 'red-txt' : '',
        loading: isBatteryLoading('SysHB'),
        expectedSize: 7,
        topicTooltip: getTopicForTooltip('SysHB', topicDict),
      },
      {
        field: 'DisChrg Power Lim',
        value: batteryRt[getKey('DischCurrLimit', bmsId)],
        loading: isBatteryLoading('DischCurrLimit'),
        expectedSize: 5,
        topicTooltip: getTopicForTooltip('DischCurrLimit', topicDict),
      },
      {
        field: 'Delta Cell V',
        value: formatValue(
          batteryRt[getKey('MaxCellV', bmsId)] -
            batteryRt[getKey('MinCellV', bmsId)],
          3,
        ),
        loading: isBatteryLoading('MaxCellV') || isBatteryLoading('MinCellV'),
        expectedSize: 4,
        topicTooltip: getTopicForTooltip('MaxCellV', topicDict),
      },
      {
        field: 'Delta Cell T',
        value: getTemperatureString(
          batteryRt[getKey('MaxCellT', bmsId)] -
            batteryRt[getKey('MinCellT', bmsId)],
        ),
        class:
          batteryRt[getKey('MaxCellT', bmsId)] - batteryRt[getKey('MinCellT')] >
          +Thresh_DeltaCellT
            ? 'red-txt'
            : '',
        loading: isBatteryLoading('MaxCellT') || isBatteryLoading('MinCellT'),
        topicTooltip: getTopicForTooltip('MaxCellT', topicDict),
      },
    ];
  };

  const isBatteryLoading = (field) => {
    return !Object.keys(batteryRt).includes(getKey(field, bmsId));
  };

  const isRackLoading = (rackObj, field) => {
    return !Object.keys(rackObj).includes(field);
  };

  const {
    Thresh_CellTMax,
    Thresh_CellTMin,
    Thresh_CellVHigh,
    Thresh_CellVLow,
    Thresh_DeltaCellT,
    Thresh_DeltaV,
    Thresh_LowerSOC,
    Thresh_UpperSOC,
    Thresh_LowerSOH,
    Thresh_HB_BMS_TimeOut,
  } = siteMeta ? siteMeta : {};

  const isRackModeIsGray =
    +Thresh_HB_BMS_TimeOut < currHBInterval ||
    +Thresh_HB_BMS_TimeOut < currCounterInterval;
  let dscr = batteryRt?.Description || '';
  if (dscr) {
    dscr = ` - ${dscr}`;
  }

  const rackModeClass = (rack) => {
    let color;
    if (isRackModeIsGray) {
      color = 'gray';
    } else if (rack.mode === 0) {
      color = 'green';
    } else {
      color = 'red';
    }
    return `circle ${color}`;
  };

  // Function to open the Rack popup
  const openRackPopup = (rack) => {
    setSelectedRack(rack);
    setIsRackPopupOpen(true);
  };

  // Function to close the Rack popup
  const closeRackPopup = () => {
    setIsRackPopupOpen(false);
  };

  const getBMSIndexByPrefix = (someBmsId) => someBmsId.split('_')[1];

  return (
    <div className={`pcs-right-container ${fromPV ? 'pv' : ''}`}>
      <div className='cell'>
        <div className='unit-info'>
          <div className='unit-col' style={{ maxHeight: '100%' }}>
            <div className='title-large'>
              {`${unitName && unitName + ' '}System BMS ${getBMSIndexByPrefix(
                bmsId,
              )}${
                //  bmsIndex + 1
                dscr || ''
              }`}
            </div>
            {getInfoDict() === null || !getInfoDict().length ? (
              <div className='bms-placeholder'> NO DATA</div>
            ) : (
              <>
                <div className='info-grid bms'>
                  {getInfoDict().map((f, i) => (
                    <React.Fragment key={i}>
                      <div className='title'>{f.field}</div>
                      <div className={f.class}>
                        <TextLoader
                          toolTipContent={f.topicTooltip}
                          orientation='l'
                          loading={f.loading}
                          expectedSize={f.expectedSize}
                        >
                          {f.value}
                        </TextLoader>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </>
            )}
            {Battery_BATT_RackTable_Enable ? (
              <div className='rack-table-container'>
                {!isRackPointsDrivenBySiteMeta ? (
                  <table className='rack-table'>
                    <thead>
                      <tr>
                        <th>Rack #</th>
                        <th>Contactor Status</th>
                        <th>SOC</th>
                        <th>Rack Voltage</th>
                        <th>Rack Current</th>
                        <th>Min Cell V</th>
                        <th>Max Cell V</th>
                        <th>ΔV</th>
                        <th>Min Cell T</th>
                        <th>Max Cell T</th>
                        <th>ΔT</th>
                        <th>SOH</th>
                        <th>Alarms</th>
                      </tr>
                    </thead>
                    <tbody>
                      {racksArr.map((rack, i) => (
                        <tr
                          key={i + 1}
                          onClick={() =>
                            openRackPopup({
                              name: getRackName(i),
                              id: rackObjects[i].id,
                            })
                          }
                        >
                          <td>{getRackName(i)}</td>
                          <td>
                            <span className={rackModeClass(rack)} />
                          </td>
                          <td
                            className={`${
                              +rack.SOC > +Thresh_UpperSOC ? 'red-txt' : ''
                            } ${
                              +rack.SOC < +Thresh_LowerSOC ? 'blue-text' : ''
                            }`}
                          >
                            <TextLoader
                              after='%'
                              orientation='c'
                              fontSise={12}
                              loading={rack.SOC === undefined}
                            >
                              {rack.SOC}
                            </TextLoader>
                          </td>
                          <td>
                            <TextLoader
                              orientation='c'
                              fontSise={12}
                              loading={rack.rackVoltage === undefined}
                            >
                              {rack.rackVoltage}
                            </TextLoader>
                          </td>
                          <td>
                            <TextLoader
                              orientation='c'
                              fontSise={12}
                              loading={rack.rackCurrent === undefined}
                            >
                              {rack.rackCurrent}
                            </TextLoader>
                          </td>
                          <td
                            className={`${
                              +rack.minCellV < +Thresh_CellVLow
                                ? 'blue-text'
                                : ''
                            }`}
                          >
                            <TextLoader
                              orientation='c'
                              fontSise={12}
                              loading={rack.minCellV === undefined}
                            >
                              {rack.minCellV}
                            </TextLoader>
                          </td>
                          <td
                            className={`${
                              +rack.maxCellV > +Thresh_CellVHigh
                                ? 'red-txt'
                                : ''
                            }`}
                          >
                            <TextLoader
                              orientation='c'
                              fontSise={12}
                              loading={rack.maxCellV === undefined}
                            >
                              {rack.maxCellV}
                            </TextLoader>
                          </td>
                          <td
                            className={`${
                              +rack.deltaV > +Thresh_DeltaV ? 'red-txt' : ''
                            }`}
                          >
                            <TextLoader
                              orientation='c'
                              fontSise={12}
                              loading={rack.deltaV === undefined}
                            >
                              {rack.deltaV}
                            </TextLoader>
                          </td>
                          <td
                            className={`${
                              +rack.minCellT < +Thresh_CellTMin
                                ? 'blue-text'
                                : ''
                            }`}
                          >
                            <TextLoader
                              orientation='c'
                              fontSise={12}
                              loading={rack.minCellT === undefined}
                            >
                              {rack.minCellT}
                            </TextLoader>
                          </td>
                          <td
                            className={`${
                              +rack.maxCellT > +Thresh_CellTMax ? 'red-txt' : ''
                            }`}
                          >
                            <TextLoader
                              orientation='c'
                              fontSise={12}
                              loading={rack.maxCellT === undefined}
                            >
                              {rack.maxCellT}
                            </TextLoader>
                          </td>
                          <td
                            className={`${
                              +rack.deltaT >= +Thresh_DeltaCellT
                                ? 'red-txt'
                                : ''
                            }`}
                          >
                            <TextLoader
                              orientation='c'
                              fontSise={12}
                              loading={rack.deltaT === undefined}
                            >
                              {rack.deltaT
                                ? getTemperatureString(+rack.deltaT)
                                : '-'}
                            </TextLoader>
                          </td>
                          <td
                            className={`${
                              +rack.SOH < +Thresh_LowerSOH ? 'red-txt' : ''
                            }`}
                          >
                            <TextLoader
                              orientation='c'
                              fontSise={12}
                              loading={rack.SOH === undefined}
                            >
                              {rack.SOH}
                            </TextLoader>
                          </td>
                          <td
                            className={`${+rack.alarms >= 1 ? 'red-txt' : ''}`}
                          >
                            <TextLoader
                              orientation='c'
                              fontSise={12}
                              loading={rack.alarms === undefined}
                            >
                              {rack.alarms}
                            </TextLoader>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <table className='rack-table'>
                    <thead>
                      <tr>
                        <th>Rack</th>
                        {rackPointNames.map((pointName) => (
                          <th key={`rackHeader${bmsIndex}${pointName}`}>
                            {pointName}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {racksArr.map((rack, i) => (
                        <tr
                          key={`rackRow${bmsIndex}${i + 1}`}
                          onClick={() =>
                            openRackPopup({
                              name: rackObjects[i].name,
                              id: rackObjects[i].id,
                            })
                          }
                        >
                          <td>{rackObjects[i].name}</td>
                          {rackPointNames.map((pointName) => (
                            <td key={`rackCell${bmsIndex}${pointName}`}>
                              <TextLoader
                                orientation='c'
                                fontSise={12}
                                loading={rack[pointName] === undefined}
                              >
                                {rack[pointName]}
                              </TextLoader>
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {/* Rack Popup */}
      <Popup
        isOpen={isRackPopupOpen}
        title={`Rack ${selectedRack?.name} details`}
        close={closeRackPopup}
        bodyClass='rack-basic-popup-content'
      >
        {selectedRack && (
          <RackContainerPopup
            rackId={selectedRack.id}
            type='basic'
            currentUnitDeviceId={currentUnitDeviceId}
            siteMeta={siteMeta}
          />
        )}
      </Popup>
    </div>
  );
};

export default storeConnector(RackContainer_default, {
  service: ['currentUnitDeviceId'],
  config: ['siteMeta'],
  mqtt: ['topicDict'],
});
